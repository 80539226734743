import { parseServerError, useEquipmentAttributeTreeInOrgPresetCreate, useEquipmentClasses } from '@aisekisan/anya-api'
import type { EquipmentAttributeTreeType } from '@aisekisan/anya-api'
import { CopyProperty, serializeTreeNode } from '@aisekisan/preset-property'
import type { EquipmentAttributeTreeNode } from '@aisekisan/preset-property'
import { useIntl } from '@aisekisan/bond'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useLocation } from '@/hooks/use-location'

interface CopyOrgPropertyProps {
  orgPresetID: string
  tree: EquipmentAttributeTreeNode
  treeType: EquipmentAttributeTreeType
  equipmentClassOther: string
  close: () => void
}

export function CopyOrgProperty(props: CopyOrgPropertyProps): JSX.Element {
  const { orgPresetID, tree, treeType, equipmentClassOther, close } = props

  const t = useT()
  const { navigate } = useLocation()
  const { lang } = useIntl()

  const { data } = useEquipmentClasses()
  const equipmentClasses = (data ?? [])
    .filter(equipmentClass => equipmentClass.locale === lang)
    .map(({ equipmentClass, localed }) => {
      return { equipmentClass, name: localed }
    })

  const copy = useEquipmentAttributeTreeInOrgPresetCreate({ orgPresetID })

  return (
    <>
      <CopyProperty
        equipmentClasses={equipmentClasses}
        isPending={copy.isPending}
        close={close}
        copy={(equipmentClass) => {
          copy.mutate(
            {
              equipmentClass,
              equipmentClassOther,
              tree: serializeTreeNode(tree),
              treeType,
            },
            {
              onSuccess: () => {
                close()
                navigate(`/org-preset/${orgPresetID}`)
              },
            },
          )
        }}
      />
      {copy.isError
        ? (
            <ErrorAlert
              title={t('preset.equipment.property.copy.error')}
              help={t('all.error.review')}
              detail={parseServerError(copy.error)}
              onClose={() => copy.reset()}
            />
          )
        : null}
    </>
  )
}
