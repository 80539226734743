import type { TableColumnDefinition } from '@aisekisan/bond'
import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Text,
  createTableColumn,
} from '@aisekisan/bond'
import { SubtractCircleRegular } from '@fluentui/react-icons'
import type { OrgInvitationDetail, OrgRole } from '@aisekisan/anya-api'
import { useFormatDate } from '@/utils/date'
import { T } from '@/libs/intl/t'

interface Props {
  role: OrgRole
  invitations: OrgInvitationDetail[]
  setRevokeInvitation: (invitation: OrgInvitationDetail) => void
}

export function OrgInvitationTable(props: Props): JSX.Element {
  const { setRevokeInvitation, invitations, role } = props

  const formatDate = useFormatDate('short-short')

  const columns: TableColumnDefinition<OrgInvitationDetail>[] = [
    createTableColumn<OrgInvitationDetail>({
      columnId: 'email',
      renderHeaderCell: () => <T id="account.field.email" />,
      renderCell: invitation => invitation.email,
      compare: (a, b) => a.email.localeCompare(b.email),
    }),
    createTableColumn<OrgInvitationDetail>({
      columnId: 'role',
      renderHeaderCell: () => <T id="org.role.label" />,
      renderCell: invitation => invitation.role,
    }),
    createTableColumn<OrgInvitationDetail>({
      columnId: 'createdAt',
      renderHeaderCell: () => <T id="org.invitation.invited" />,
      renderCell: invitation => formatDate(invitation.createdAt),
      compare: (a, b) => a.createdAt.localeCompare(b.createdAt),
    }),
    createTableColumn<OrgInvitationDetail>({
      columnId: 'expiresAt',
      renderHeaderCell: () => <T id="org.invitation.expired" />,
      renderCell: invitation => formatDate(invitation.expiresAt),
      compare: (a, b) => a.expiresAt.localeCompare(b.expiresAt),
    }),
    createTableColumn<OrgInvitationDetail>({
      columnId: 'actions',
      renderHeaderCell: () => '',
      renderCell: (invitation) => {
        if (role === 'owner') {
          return (
            <Button
              aria-label="Remove"
              appearance="transparent"
              icon={<SubtractCircleRegular />}
              onClick={() => setRevokeInvitation(invitation)}
            >
              <T id="org.invitation.remove" />
            </Button>
          )
        }
      },
    }),
  ]

  return (
    <DataGrid
      items={invitations}
      columns={columns}
      getRowId={invitation => invitation.id}
      focusMode="composite"
      sortable
      defaultSortState={{ sortColumn: 'email', sortDirection: 'ascending' }}
      resizableColumns
      columnSizingOptions={{
        email: { minWidth: 300 },
      }}
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>
              <Text weight="semibold">{renderHeaderCell()}</Text>
            </DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<OrgInvitationDetail>>
        {({ item, rowId }) => (
          <DataGridRow<OrgInvitationDetail> key={rowId}>
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  )
}
