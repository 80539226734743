import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  OverlaySpinner,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { parseServerError, useOrgInvitationDelete } from '@aisekisan/anya-api'
import type { OrgInvitationDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  orgID: string
  invitation: OrgInvitationDetail
  close: () => void
}

export function InvitationRevoke(props: Props): JSX.Element {
  const { orgID, invitation, close } = props
  const { id, email } = invitation

  const t = useT()
  const styles = useStyles()

  const deleteInvite = useOrgInvitationDelete({ orgID })

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner visible={deleteInvite.isPending} appearance="primary" />
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="org.member.revoke.invitation.title" />
          </DialogTitle>
          <DialogContent>
            {deleteInvite.isError
              ? (
                  <ErrorAlert
                    title={t('org.member.remove.error.title')}
                    help={<T id="all.error.retry" />}
                    detail={parseServerError(deleteInvite.error)}
                    onClose={() => deleteInvite.reset()}
                  />
                )
              : null}
            <div className={styles.content}>
              <Body1>
                <T id="org.member.revoke.invitation.helper {{.email}}" params={{ email }} />
              </Body1>
              <Body1>
                <T id="org.member.revoke.invitation.helper.2" />
              </Body1>
              <Body1>
                <T id="org.member.revoke.invitation.helper.3" />
              </Body1>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={() => deleteInvite.mutate(id, { onSuccess: close })}
            >
              <T id="org.member.revoke.invitation.submit" />
            </Button>
            <Button onClick={close}>
              <T id="org.member.remove.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
