import type { ZoneDetail } from '@aisekisan/anya-api'
import { Button, Panel } from '@aisekisan/bond'
import { Add24Regular, SquareHint24Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { ConstructionZoneCreate } from './create'
import { ConstructionZoneTable } from './table'
import { ConstructionZoneUpdate } from './update'
import { T } from '@/libs/intl/t'

export function ConstructionZoneList(props: {
  constructionID: string
}): ReactElement {
  const { constructionID } = props

  const [create, setCreate] = useState<boolean>(false)
  const [update, setUpdate] = useState<ZoneDetail | null>(null)

  return (
    <Panel
      title={<T id="construction.zone.list" />}
      icon={<SquareHint24Regular />}
      actions={(
        <Button icon={<Add24Regular />} onClick={() => setCreate(true)}>
          <T id="construction.zone.create" />
        </Button>
      )}
    >
      {create && (
        <ConstructionZoneCreate
          constructionID={constructionID}
          onClose={() => setCreate(false)}
        />
      )}

      {update && (
        <ConstructionZoneUpdate
          constructionID={constructionID}
          zone={update}
          onClose={() => setUpdate(null)}

        />
      )}

      <ConstructionZoneTable
        constructionID={constructionID}
        setUpdate={zone => void setUpdate(zone)}
      />
    </Panel>
  )
}
