import { makeStyles, tokens } from '@aisekisan/bond'
import { useListPages, useOrgAppEnabledMap, useOrgReportTemplates } from '@aisekisan/anya-api'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import { ReportSummary } from '../report/report'
import { PageList } from './page/list'

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
    columnGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  category: CategoryDetail
  construction: ConstructionDetail
}

export function CategoryDetailComp(props: Props): JSX.Element {
  const { category, construction } = props

  const styles = useStyles()

  const pages = useListPages({ categoryID: category.id }).data ?? []
  const templates = useOrgReportTemplates({ orgID: category.orgID }).data ?? []

  // Prefetch oranization's app scopes ASAP
  useOrgAppEnabledMap({ orgID: category.orgID })

  return (
    <div className={styles.container}>
      <ReportSummary
        category={category}
        construction={construction}
        pages={pages}
        templates={templates}
      />
      <PageList
        orgID={category.orgID}
        categoryID={category.id}
        constructionID={construction.id}
        pages={pages}
      />
    </div>
  )
}
