// listZones

import { server } from '@aisekisan/anya-api/src/server'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

const QUERY_KEY = 'zone'

export interface FormatZoneDetail {
  id: string
  name: string
}

export function useListZones(constructionID: string): UseQueryResult<FormatZoneDetail[]> {
  return useQuery({
    queryFn: () =>
      server.listZones(constructionID, 0, 200),
    queryKey: [QUERY_KEY, 'list', constructionID],
    select: data => [...data.map(zone => ({
      id: zone.id,
      name: zone.name,
    })), {
      id: 'non_zone',
      name: 'non_zone',
    }],
  })
}
