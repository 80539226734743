import { type ReactElement, useRef } from 'react'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import { makeStyles } from '@aisekisan/bond'
import { ReportExport } from '../export/button'
import type { ReportHead } from '../type'
import { downloadReport, getRef } from '../export/download'
import { ZoneReportHeader } from './header'
import { ZoneReportOverview } from './overview'
import { ZoneReportBody } from './body'
import { useListZones } from './utils'
import type { ReportZoneRow } from './type'

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
})

interface Props {
  construction: ConstructionDetail
  category: CategoryDetail
  reportHeads: ReportHead[]
  reportRows: ReportZoneRow[]
}

export function ZoneReportExport(props: Props): ReactElement {
  const { construction, category, reportHeads, reportRows } = props

  const s = useStyles()
  const tableRef = useRef<HTMLTableElement>(null)
  const zones = useListZones(construction.id).data ?? []

  return (
    <>
      <ReportExport
        download={(format) => {
          downloadReport({
            table: getRef(tableRef),
            name: `${construction.name}-${category.name}`,
            format,
          })
        }}
      />
      <div className={s.hidden}>
        <table ref={tableRef}>
          <tbody>
            <ZoneReportOverview
              category={category}
              construction={construction}
              reportHeads={reportHeads}
            />
            <ZoneReportHeader
              zoneHeaders={zones}
            />
            <ZoneReportBody
              zoneHeaders={zones}
              reportRows={reportRows}
            />
          </tbody>
        </table>
      </div>
    </>
  )
}
