import { Button, Panel, makeStyles, tokens } from '@aisekisan/bond'
import {
  AddCircle20Regular,
  BuildingHome24Regular,
} from '@fluentui/react-icons'
import { useState } from 'react'
import { useConstructionList } from '@aisekisan/anya-api'
import { ConstructionCard } from './card'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'
import { ConstructionCreate } from '@/components/construction/create'

interface Props {
  orgID: string
}

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    rowGap: tokens.spacingHorizontalL,
    columnGap: tokens.spacingVerticalL,
  },
})

export function OrgConstructionList(props: Props) {
  const { orgID } = props

  const styles = useStyles()

  const [createConstruction, setCreateConstruction] = useState<boolean>(false)

  const list = useConstructionList({ orgID }).data ?? []

  return (
    <Panel
      title={<T id="construction.list.title" />}
      icon={<BuildingHome24Regular />}
      actions={(
        <Button
          icon={<AddCircle20Regular />}
          onClick={() => setCreateConstruction(true)}
        >
          <T id="construction.list.create" />
        </Button>
      )}
    >
      {createConstruction && (
        <ConstructionCreate
          orgID={orgID}
          close={() => setCreateConstruction(false)}
        />
      )}
      {list.length > 0
        ? (
            <div className={styles.container}>
              {list.sort((a, b) => a.code.localeCompare(b.code)).map(construction => (
                <ConstructionCard
                  key={construction.id}
                  construction={construction}
                />
              ))}
            </div>
          )
        : <EmptyContent />}
    </Panel>
  )
}
