import type { REPORT_TEMPLATES } from '@aisekisan/bond'
import {
  Dropdown,
  Field,
  Option,
} from '@aisekisan/bond'
import { type ReactElement, useState } from 'react'
import type { OrgReportingTemplateDetail } from '@aisekisan/anya-api'
import { useT } from '@/libs/intl/useT'

interface ExportButtonProps {
  templates: OrgReportingTemplateDetail[]
  templateID: REPORT_TEMPLATES
  setTemplateID: (templateID: REPORT_TEMPLATES) => void
}

export function ReportTemplate(props: ExportButtonProps): ReactElement {
  const { templates, templateID, setTemplateID } = props

  const t = useT()

  const template = templates.find(template => template.template === templateID)?.templateName

  const [value, setValue] = useState<string>(template ?? t('category.report.template-default'))
  const [selectedOptions, setSelectedOptions] = useState<string[]>([template ?? ''])

  return (
    <Field label={t('category.report.template')} style={{ width: '250px' }}>
      <Dropdown
        appearance="filled-darker"
        value={value}
        onOptionSelect={(_, data) => {
          setSelectedOptions(data.selectedOptions)
          setValue(data.optionText ?? '')
          setTemplateID(data.optionValue as REPORT_TEMPLATES)
        }}
        selectedOptions={selectedOptions}
      >
        {templates.map(({ id, templateName, template }) => {
          return (
            <Option
              key={id}
              text={templateName}
              value={template}
            >
              {templateName}
            </Option>
          )
        })}
      </Dropdown>
    </Field>
  )
}
