import type { ZoneDetail } from '@aisekisan/anya-api'
import { useConstructionZoneList } from '@aisekisan/anya-api'
import type { TableColumnDefinition } from '@aisekisan/bond'
import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Panel, Text, createTableColumn, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { Edit24Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { getColor } from './create'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'

const useStyles = makeStyles({
  table: {
    marginBottom: tokens.spacingVerticalL,
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding(tokens.spacingHorizontalL),
    // Override shorthand padding
    paddingTop: 0,
  },
})

export function ConstructionZoneTable(props: {
  constructionID: string
  setUpdate: (zone: ZoneDetail) => void
}): ReactElement {
  const { constructionID, setUpdate } = props

  const t = useT()
  const styles = useStyles()

  const zones = useConstructionZoneList({ constructionID }).data ?? []

  const columns: TableColumnDefinition<ZoneDetail>[] = [
    createTableColumn<ZoneDetail>({
      columnId: 'name',
      renderHeaderCell: () => {
        return <T id="construction.zone.name" />
      },
      renderCell: zone => zone.name,
    }),
    createTableColumn<ZoneDetail>({
      columnId: 'color',
      renderHeaderCell: () => {
        return <T id="construction.zone.color" />
      },
      renderCell: zone => <Text>{getColor(t, zone.color)}</Text>,
    }),
    createTableColumn<ZoneDetail>({
      columnId: 'actions',
      renderHeaderCell: () => {
        return ''
      },
      renderCell: zone => (
        <Button
          aria-label="Update"
          appearance="transparent"
          icon={<Edit24Regular />}
          onClick={() => setUpdate(zone)}
        >
          <T id="construction.zone.update" />
        </Button>
      ),
    }),
  ]

  if (zones.length === 0)
    return <EmptyContent />

  return (
    <Panel className={styles.table}>

      <DataGrid
        items={zones ?? []}
        columns={columns}
        sortable
        getRowId={zone => zone.id}
        focusMode="composite"
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>
                <Text weight="semibold">{renderHeaderCell()}</Text>
              </DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<ZoneDetail>>
          {({ item, rowId }) => (
            <DataGridRow<ZoneDetail> key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </Panel>
  )
}
