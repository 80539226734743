import { OverlaySpinner } from '@aisekisan/bond'
import { useEquipmentAttributeTreeInOrgDetail, useOrgPresetDetail } from '@aisekisan/anya-api'
import { AppPage } from '@/AppPage'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { OrgEquipmentPropertyDetail } from '@/components/org/property/detail'

export function PropertyDetailPage(props: { treeID: string }): JSX.Element {
  const { treeID } = props

  const t = useT()

  const detail = useEquipmentAttributeTreeInOrgDetail({ treeID })

  const preset = useOrgPresetDetail({
    orgPresetID: detail.data?.presetID ?? '',
  }).data

  if (detail.isLoading)
    return <OverlaySpinner visible={detail.isLoading} />

  return (
    <AppPage
      breadcrumbs={[
        {
          title: preset?.name ?? '',
          href: `/org-preset/${preset?.id}`,
        },
        {
          title: `${t('preset.equipment.property.label')}`,
          href: ``,
        },
      ]}
    >
      {detail.isError && <T id="error.message" />}

      <div>
        {detail.data
          ? (
              <OrgEquipmentPropertyDetail
                equipmentAttributeTree={detail.data}
                orgPresetID={detail.data.presetID}
              />
            )
          : (
              <T id="error.message" />
            )}
      </div>

    </AppPage>
  )
}
