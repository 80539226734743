import type { ConstructionRole } from '@aisekisan/anya-api'
import { useAccountInfo, useConstructionMemberList } from '@aisekisan/anya-api'

export function useConstructionRole(constructionID: string): ConstructionRole {
  const account = useAccountInfo().data
  const memberList = useConstructionMemberList({ constructionID }).data

  if (account === undefined)
    return 'viewer' as ConstructionRole

  if (memberList === undefined)
    return 'viewer' as ConstructionRole

  const role = memberList.find(member => member.email === account.email)?.role ?? 'viewer'
  return role
}
