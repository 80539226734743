import { Button, OverlaySpinner, Title3, makeStyles, tokens } from '@aisekisan/bond'
import { useState } from 'react'
import { DeleteRegular } from '@fluentui/react-icons'
import { useOrgDetail, useOrgPresetDetail } from '@aisekisan/anya-api'
import { PresetOverview } from '@aisekisan/preset-property'
import { AppPage } from '@/AppPage'
import { ContainerWrapper } from '@/ContainerWrapper'
import { OrgEquipmentPropertyList } from '@/components/org/property/list'
import { useT } from '@/libs/intl/useT'
import { NotFound } from '@/components/not-found'
import { T } from '@/libs/intl/t'
import { OrgPresetDelete } from '@/components/org/preset/delete'
import { OrgPresetEdit } from '@/components/org/preset/edit'

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
  },
})

export function OrgPresetDetailPage(props: { id: string }): JSX.Element {
  const { id } = props

  const t = useT()
  const styles = useStyles()

  const [editPreset, setEditPreset] = useState<boolean>(false)
  const [deletePreset, setDeletePreset] = useState<boolean>(false)

  const { data: preset, isLoading, isError } = useOrgPresetDetail({ orgPresetID: id })
  const { data: org } = useOrgDetail({ orgID: preset?.orgID ?? '' })

  if (isLoading)
    return <OverlaySpinner visible={isLoading} />

  return (
    <AppPage
      breadcrumbs={preset
        ? [
            {
              title: `${t('org.title')} ${org?.name}`,
              href: `/org/${org?.id}`,
            },
            {
              title: preset.name,
              href: `/org-preset/${preset.id}`,
            },
          ]
        : []}
      title={(
        <div className={styles.row}>
          <Title3>
            <span>{t('org.preset.label')}</span>
            <span>{preset?.name}</span>
          </Title3>
          <Button icon={<DeleteRegular />} onClick={() => setDeletePreset(true)}>
            <T id="preset.action.delete" />
          </Button>
        </div>
      )}
    >
      {isError ? <NotFound /> : null}
      {preset
        ? (
            <ContainerWrapper>
              <PresetOverview preset={preset} setEditPreset={setEditPreset} />
              <OrgEquipmentPropertyList orgPresetID={id} />
              {editPreset && <OrgPresetEdit preset={preset} close={() => setEditPreset(false)} />}
              {deletePreset && org && <OrgPresetDelete preset={preset} close={() => setDeletePreset(false)} />}
            </ContainerWrapper>
          )
        : null}
    </AppPage>
  )
}
