import { useQuery } from '@tanstack/react-query'
import { server } from '../server'
import type { App } from '../generated'

const KEY = 'org-app-scope'

function getOrgAppScopesListQueryKey(orgID: string) {
  return [KEY, 'org-app-scope', 'list', orgID]
}

export type AppScopeEnabledMap = Record<App, boolean>

export function useOrgAppEnabledMap(params: { orgID: string }) {
  const { orgID } = params

  return useQuery({
    queryKey: getOrgAppScopesListQueryKey(orgID),
    queryFn: () => server.listOrgAppScopes(orgID),
    select: (appScopes) => {
      const appScopeEnabledMap: AppScopeEnabledMap = { AIS: false, DRAW: false }
      appScopes.forEach(appScope => appScopeEnabledMap[appScope.app] = true)
      return appScopeEnabledMap
    },
  })
}
