import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, OverlaySpinner, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import type { PageDetail, PageUpdateParams } from '@aisekisan/anya-api'
import { parseServerError, usePageUpdate } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

interface UpdatePageProps {
  page: PageDetail
  close: () => void
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

export function RenamePage(props: UpdatePageProps): JSX.Element {
  const { page, close } = props

  const t = useT()
  const styles = useStyles()

  const schema = z.object({
    name: z.string().min(1, { message: t('form.error.required_field') }),
  })

  const form = useForm<PageUpdateParams>({
    defaultValues: {
      name: page.name,
    },
    resolver: zodResolver(schema),
  })

  const { formState: { errors } } = form

  const update = usePageUpdate({ pageID: page.id })

  const onSubmit = form.handleSubmit((values: PageUpdateParams) => {
    const { name } = values
    update.mutate({ name, scale: page.scale }, { onSuccess: close })
  })

  return (
    <Dialog open={!!page} onOpenChange={close}>
      <DialogSurface>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <OverlaySpinner visible={update.isPending} appearance="primary" />
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="page.rename.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {update.isError
                ? (
                    <ErrorAlert
                      title={t('page.rename.error.title')}
                      help={t('all.error.review')}
                      detail={parseServerError(update.error)}
                      onClose={() => update.reset()}
                    />
                  )
                : null}
              <Field
                label={t('category.page.rename')}
                validationState={errors.name ? 'error' : 'none'}
                validationMessage={errors.name?.message}
              >
                <Input
                  {...form.register('name')}
                  size="large"
                  appearance="filled-darker"
                  defaultValue={form.getValues('name')}
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button appearance="primary" type="submit">
                <T id="page.rename.submit" />
              </Button>
              <Button onClick={close}>
                <T id="page.rename.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
