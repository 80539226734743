import { parseServerError, useCreateConstructionZone } from '@aisekisan/anya-api'
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Dropdown, Field, Input, Option, OverlaySpinner, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular, Square20Filled } from '@fluentui/react-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TLDefaultColorStyle, TLDefaultColorThemeColor } from '@tldraw/tldraw'
import { getDefaultColorTheme } from '@tldraw/tldraw'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'
import type { INTL_DICT_EN } from '@/libs/intl/dicts/en'

interface ConstructionZoneCreate {
  name: string
  color: string
}

interface AnnotShapeColorOption {
  label: string
  value: TLDefaultColorStyle
}

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
})

export function getColor(t: (id: keyof typeof INTL_DICT_EN) => string, value: string) {
  const ANNOT_SHAPE_COLOR_OPTIONS: AnnotShapeColorOption[] = [
    { label: t('annot.color.red'), value: 'red' },
    { label: t('annot.color.orange'), value: 'orange' },
    { label: t('annot.color.yellow'), value: 'yellow' },
    { label: t('annot.color.green'), value: 'green' },
    { label: t('annot.color.lightBlue'), value: 'light-blue' },
    { label: t('annot.color.blue'), value: 'blue' },
    { label: t('annot.color.violet'), value: 'violet' },
    { label: t('annot.color.lightViolet'), value: 'light-violet' },
    { label: t('annot.color.lightRed'), value: 'light-red' },
  ]

  return ANNOT_SHAPE_COLOR_OPTIONS.find(color => color.value === value)?.label ?? ''
}

export function ConstructionZoneCreate(props: {
  constructionID: string
  onClose: () => void
}): ReactElement {
  const { constructionID, onClose } = props

  const [selectedOptions, setSelectedOptions] = useState<string[]>([''])
  const [value, setValue] = useState<string>('')

  const t = useT()
  const styles = useStyles()

  const theme = getDefaultColorTheme({ isDarkMode: false })

  const ANNOT_SHAPE_COLOR_OPTIONS: AnnotShapeColorOption[] = [
    { label: t('annot.color.red'), value: 'red' },
    { label: t('annot.color.orange'), value: 'orange' },
    { label: t('annot.color.yellow'), value: 'yellow' },
    { label: t('annot.color.green'), value: 'green' },
    { label: t('annot.color.lightBlue'), value: 'light-blue' },
    { label: t('annot.color.blue'), value: 'blue' },
    { label: t('annot.color.violet'), value: 'violet' },
    { label: t('annot.color.lightViolet'), value: 'light-violet' },
    { label: t('annot.color.lightRed'), value: 'light-red' },
  ]

  const schema = z.object({
    name: z.string().min(1, { message: t('form.error.required_field') }),
    color: z.string().min(1, { message: t('form.error.required_field') }),
  })

  const form = useForm<ConstructionZoneCreate>({
    defaultValues: { name: '', color: '' },
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  })

  const { formState: { errors } } = form

  const create = useCreateConstructionZone({ constructionID })

  const onSubmit = form.handleSubmit((body) => {
    create.mutate(body, { onSuccess: onClose })
  })

  return (
    <Dialog open={!!constructionID} onOpenChange={onClose} modalType="alert">
      <DialogSurface>
        <form
          onSubmit={onSubmit}
          className={styles.form}
        >
          <DialogBody>
            <OverlaySpinner visible={create.isPending} appearance="primary" />
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={onClose}
                  />
                </DialogTrigger>
              )}
            >
              <T id="construction.zone.create" />
            </DialogTitle>
            <DialogContent>
              <div className={styles.content}>
                {create.isError
                  ? (
                      <ErrorAlert
                        title={t('construction.zone.create-error')}
                        help={t('all.error.review')}
                        detail={parseServerError(create.error)}
                        onClose={() => create.reset()}
                      />
                    )
                  : null}
                <Field
                  label={<T id="construction.zone.name" />}
                  validationState={errors.name ? 'error' : 'none'}
                  validationMessage={errors.name?.message}
                >
                  <Input
                    size="large"
                    {...form.register('name')}
                    appearance="filled-darker"
                  />
                </Field>
                <Controller
                  control={form.control}
                  name="color"
                  render={({ field: { onChange } }) => (
                    <Field
                      label={<T id="construction.zone.color" />}
                      validationState={errors.color ? 'error' : 'none'}
                      validationMessage={errors.color?.message}
                    >
                      <Dropdown
                        size="large"
                        appearance="filled-darker"
                        selectedOptions={selectedOptions}
                        value={value}
                        onOptionSelect={(_e, data) => {
                          onChange(data.optionValue)
                          setSelectedOptions(data.selectedOptions)
                          if (data.optionText)
                            setValue(data.optionText)
                        }}
                      >
                        {ANNOT_SHAPE_COLOR_OPTIONS.map(color => (
                          <Option
                            key={`${color.value}`}
                            value={color.value}
                            text={color.label}
                          >
                            <Square20Filled style={{ color: (theme[color.value as keyof typeof theme] as TLDefaultColorThemeColor).solid }} />
                            {`${color.label}`}
                          </Option>
                        ))}
                      </Dropdown>
                    </Field>
                  )}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                appearance="primary"
                type="submit"
              >
                <T id="construction.zone.create" />
              </Button>
              <Button onClick={onClose}>
                <T id="dialog.action.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
