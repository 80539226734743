import type {
  TableColumnDefinition,
} from '@aisekisan/bond'
import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Panel,
  Text,
  createTableColumn,
  makeStyles,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import { EditRegular } from '@fluentui/react-icons'
import type {
  ConstructionModuleDetail,
} from '@aisekisan/anya-api'
import { useConstructionModuleList } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'

interface Props {
  constructionID: string
  setUpdate: (member: ConstructionModuleDetail) => void
}

const useStyles = makeStyles({
  table: {
    marginBottom: tokens.spacingVerticalL,
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding(tokens.spacingHorizontalL),
    // Override shorthand padding
    paddingTop: 0,
  },
})

export function ConstructionModuleTable(props: Props): JSX.Element {
  const { constructionID, setUpdate } = props

  const data = useConstructionModuleList({ constructionID }).data ?? []

  const styles = useStyles()
  const columns: TableColumnDefinition<ConstructionModuleDetail>[] = [
    createTableColumn<ConstructionModuleDetail>({
      columnId: 'name',
      renderHeaderCell: () => {
        return <T id="construction.module.field.name" />
      },
      renderCell: cModule => cModule.name,

    }),
    createTableColumn<ConstructionModuleDetail>({
      columnId: 'full-name',
      renderHeaderCell: () => {
        return <T id="construction.module.field.quantity" />
      },
      renderCell: cModule => cModule.quantity,
    }),
    createTableColumn<ConstructionModuleDetail>({
      columnId: 'actions',
      renderHeaderCell: () => {
        return ''
      },
      renderCell: cModule => (
        <Button
          aria-label="update"
          appearance="transparent"
          icon={<EditRegular />}
          onClick={() => setUpdate(cModule)}
        >
          <T id="annotation.module.edit.submit" />
        </Button>
      ),
    }),
  ]

  if (data.length === 0)
    return <EmptyContent />

  return (
    <Panel className={styles.table}>

      <DataGrid
        items={data ?? []}
        columns={columns}
        getRowId={cModule => cModule.id}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>
                <Text weight="semibold">{renderHeaderCell()}</Text>
              </DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<ConstructionModuleDetail>>
          {({ item, rowId }) => (
            <DataGridRow<ConstructionModuleDetail> key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </Panel>
  )
}
