import type { ReactElement } from 'react'
import { parseServerError, useOrgPresetDelete } from '@aisekisan/anya-api'
import type { OrgPresetDetail } from '@aisekisan/anya-api'
import { PresetDelete } from '@aisekisan/preset-property'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useLocation } from '@/hooks/use-location'

interface Props {
  preset: OrgPresetDetail
  close: () => void
}

export function OrgPresetDelete(props: Props): ReactElement {
  const { preset, close } = props

  const t = useT()
  const { navigate } = useLocation()

  const deletePreset = useOrgPresetDelete({ orgID: preset.orgID })

  return (
    <>
      <PresetDelete
        preset={preset}
        isPending={deletePreset.isPending}
        deletePreset={() => {
          deletePreset.mutate(preset.id, {
            onSuccess: () => {
              close()
              navigate(`/org/${preset.orgID}`)
            },
          })
        }}
        close={close}
      />
      {deletePreset.isError
        ? (
            <ErrorAlert
              title={t('preset.delete.error.title')}
              help={<T id="all.error.review" />}
              detail={parseServerError(deletePreset.error)}
              onClose={() => deletePreset.reset()}
            />
          )
        : null}
    </>
  )
}
