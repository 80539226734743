import { Body1, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Field, Input, OverlaySpinner, makeStyles, tokens } from '@aisekisan/bond'
import { useForm } from 'react-hook-form'
import { Dismiss24Regular, Key20Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { parseServerError, useAccountOTPRegister, useEnableAccountOTP } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  divider: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    minHeight: '24px',
    backgroundColor: tokens.colorNeutralBackground1,
  },
  dividerLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  qrCode: {
    width: '300px',
    height: '300px',
    alignSelf: 'center',
  },
  helper: {
    color: tokens.colorNeutralForeground3,
    alignSelf: 'center',

  },
})

export function OTPEnable(props: { close: () => void }): JSX.Element {
  const { close } = props

  const t = useT()
  const styles = useStyles()

  const [active, setActive] = useState(0)

  const nextStep = () =>
    setActive(current => (current < 2 ? current + 1 : current))
  const prevStep = () =>
    setActive(current => (current > 0 ? current - 1 : current))

  const otpRegister = useAccountOTPRegister()

  const schema = z.object({
    passcode: z.string().min(1, { message: t('form.error.required_field') }).max(6, { message: 'Please enter a valid code' }),
  })

  const form = useForm<{ passcode: string }>({
    defaultValues: {
      passcode: '',
    },
    resolver: zodResolver(schema),
  })

  const { formState: { errors } } = form

  const enableOTP = useEnableAccountOTP()

  const onSubmit = form.handleSubmit((values) => {
    return void enableOTP.mutate(values, { onSuccess: close })
  })

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <form>
          <DialogBody>
            <OverlaySpinner visible={otpRegister.isLoading || enableOTP.isPending} appearance="primary" />
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="otp.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {enableOTP.isError
                ? (
                    <ErrorAlert
                      title={t('otp.field.otp.error')}
                      help={<T id="otp.field.otp.invalid" />}
                      detail={parseServerError(enableOTP.error)}
                      onClose={() => enableOTP.reset()}
                    />
                  )
                : null}
              {active === 0
                ? (
                    <>
                      <div className={styles.divider}>
                        <Divider className={styles.dividerLabel}>
                          Step 1:
                          {' '}
                          <T id="account-info.otp.scan-qr" />
                        </Divider>
                      </div>

                      {otpRegister.data && (
                        <div className={styles.qrCode}>
                          <img width="300px" height="300px" src={`data:image/png;base64,${otpRegister.data.qrcode}`} alt="QR Code" />
                        </div>
                      )}

                      <Body1 className={styles.helper}>
                        <T id="otp.help" />
                      </Body1>
                    </>
                  )
                : (
                    <>
                      <div className={styles.divider}>
                        <Divider className={styles.dividerLabel}>
                          Step 2:
                          {' '}
                          <T id="account-info.otp.verify" />
                        </Divider>
                      </div>
                      <Field
                        label={<T id="otp.field.otp.help" />}
                        validationState={errors.passcode ? 'error' : 'none'}
                        validationMessage={errors.passcode?.message}
                      >
                        <Input
                          type="number"
                          id="passcode"
                          maxLength={6}
                          {...form.register('passcode')}
                          placeholder="123456"
                          required
                          contentBefore={<Key20Regular />}
                          appearance="filled-darker"
                        />
                      </Field>
                    </>
                  )}
            </DialogContent>
            <DialogActions>
              {active === 0
                ? (
                    <>
                      <Button onClick={close}>
                        <T id="otp.cancel" />
                      </Button>
                      <Button appearance="primary" onClick={() => nextStep()}>
                        <T id="otp.next" />
                      </Button>
                    </>
                  )
                : (
                    <>
                      <Button onClick={() => prevStep()}>
                        <T id="otp.back" />
                      </Button>
                      <Button appearance="primary" onClick={onSubmit}>
                        <T id="otp.submit" />
                      </Button>
                    </>
                  )}
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
