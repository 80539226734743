import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  Input,
  OverlaySpinner,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { parseServerError, useConstructionDelete } from '@aisekisan/anya-api'
import type { ConstructionDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useLocation } from '@/hooks/use-location'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  construction: ConstructionDetail
  orgID: string
  close: () => void
}

export function ConstructionDelete(props: Props): JSX.Element {
  const { construction, close, orgID } = props
  const constructionID = construction.id
  const constructionName = construction.name

  const t = useT()
  const styles = useStyles()
  const { navigate } = useLocation()

  const [name, setName] = useState<string>('')
  const [error, setError] = useState<{ error: boolean, message: string } | null>(null)

  const remove = useConstructionDelete()

  return (
    <Dialog open={!!constructionID} onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner visible={remove.isPending} appearance="primary" />
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="construction.delete.title" />
          </DialogTitle>
          <DialogContent>
            {remove.isError
              ? (
                  <ErrorAlert
                    title={t('construction.delete.error.title')}
                    help={<T id="all.error.retry" />}
                    detail={parseServerError(remove.error)}
                    onClose={() => remove.reset()}
                  />
                )
              : null}
            <div className={styles.content}>
              <Body1>
                <T id="construction.delete.help" />
                <span>
                  {' '}
                  <strong>{constructionName}</strong>
                </span>
                <T id="construction.delete.rules" />
              </Body1>
              <ul style={{ margin: 0 }}>
                <li>
                  <T id="construction.delete.rules.1" />
                </li>
                <li>
                  <T id="construction.delete.rules.2" />
                </li>
                <li>
                  <T id="construction.delete.rules.3" />
                </li>
              </ul>
              <Body1>
                <T id="construction.delete.rules.4 {{.name}}" params={{ name: construction.name }} />
              </Body1>
              <Field label={t('construction.field.name')} validationState={error?.error ? 'error' : 'none'} validationMessage={error?.message}>
                <Input size="large" appearance="filled-darker" onChange={e => setName(e.target.value)}></Input>
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={() => {
                if (name === constructionName) {
                  remove.mutate(constructionID, { onSuccess: () => {
                    close()
                    navigate(`/org/${orgID}`)
                  } })
                }
                else {
                  setError({
                    error: true,
                    message: `${t('construction.delete.error-field')} ${t('conctruction.delete.open-quote')}${construction.name}${t('conctruction.delete.close-quote')}`,
                  })
                }
              }}
            >
              <T id="construction.action.delete" />
            </Button>
            <Button onClick={close}>
              <T id="dialog.action.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
