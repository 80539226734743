import type { ReportZoneData, ReportZoneRow } from '../type'

const GROUPED_KEYS: (keyof ReportZoneData)[] = [
  'material1',
  'material2',
  'cArea',
  'equipType',
  'size',
  'shape',
  'unit',
  'equipClassName',
  'zoneID',
]

function getLength(values: ReportZoneData[]): string {
  const length = values.reduce((acc, v) => {
    return acc + Number(v.value)
  }, 0)
  switch (values[0].unit) {
    case 'm':
      return (length / 1000).toFixed(1)
    case 'mm':
      return length.toFixed(1)
    case 'piece':
    default:
      return length.toString()
  }
}

export function groupReportZoneData(annots: ReportZoneData[]): ReportZoneRow[] {
  const groups = new Map<string, ReportZoneData[]>()

  annots.forEach((annot) => {
    const key = GROUPED_KEYS.map(key => JSON.stringify(annot[key])).join('-')
    const sum = [...(groups.get(key) ?? []), annot].reduce((acc, annot) => {
      const { value } = annot
      const numericValue = Number.parseFloat(value)

      if (isNaN(numericValue))
        return acc

      return acc + numericValue
    }, 0)
    groups.set(key, [{ ...annot, value: sum.toString() }])
  })

  const transform = [...groups.values()]
    .map((values) => {
      const key = values[0].zoneID
        ? `${values[0].zoneID}`
        : `non_zone`

      return { ...values[0], [key]: getLength(values) }
    })

  const rows = new Map()
  transform.forEach((data) => {
    const key = GROUPED_KEYS.map(item => JSON.stringify(data[item])).join('-')
    rows.set(key, { ...rows.get(key), ...data, length: {}, zoneID: data.zoneID })
  })

  return Array.from(rows.values()).filter(row => !(row.zoneID === 'non_zone' && Number.parseFloat(row.non_zone) === 0))
}
