import { Body1, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, OverlaySpinner, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { parseServerError, usePageDelete } from '@aisekisan/anya-api'
import type { PageDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

interface DeletePageProps {
  page: PageDetail
  close: () => void
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
})
export function DeletePage(props: DeletePageProps): JSX.Element {
  const { page, close } = props

  const t = useT()
  const styles = useStyles()

  const deletePage = usePageDelete({ categoryID: page.categoryID })

  return (
    <Dialog open={!!page.id} onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner visible={deletePage.isPending} appearance="primary" />
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="page.delete.title" />
          </DialogTitle>
          <DialogContent>
            {deletePage.isError
              ? (
                  <ErrorAlert
                    title={t('page.delete.error.title')}
                    help={t('all.error.review')}
                    detail={parseServerError(deletePage.error)}
                    onClose={() => deletePage.reset()}
                  />
                )
              : null}
            <div className={styles.content}>
              <Body1>
                <T id="page.delete.helper" />
                <span>
                  <strong>
                    {' '}
                    {page.name}
                  </strong>
                </span>
                <T id="page.delete.helper.1" />
              </Body1>
              <Body1 className={styles.content}>
                <T id="page.delete.helper.2" />
              </Body1>
              <Body1>
                <T id="page.delete.helper.3" />
              </Body1>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={() => {
                deletePage.mutate([page.id], { onSuccess: close })
              }}
            >
              <T id="page.delete.submit" />
            </Button>
            <Button onClick={close}>
              <T id="page.delete.cancel" />
            </Button>

          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
