import type {
  TableColumnDefinition,
} from '@aisekisan/bond'
import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Text,
  createTableColumn,
} from '@aisekisan/bond'
import { SubtractCircleRegular } from '@fluentui/react-icons'
import type { Locale, OrgMemberDetail, OrgRole } from '@aisekisan/anya-api'
import { UpdateRoleSelect } from './update-role'
import { T } from '@/libs/intl/t'

interface Props {
  role: OrgRole
  memberList: OrgMemberDetail[]
  locale: Locale
  orgID: string
  setRemove: (member: OrgMemberDetail) => void
}

export function OrgMemberTable(props: Props): JSX.Element {
  const { orgID, setRemove, memberList, role, locale } = props

  const columns: TableColumnDefinition<OrgMemberDetail>[] = [
    createTableColumn<OrgMemberDetail>({
      columnId: 'email',
      renderHeaderCell: () => {
        return <T id="account.field.email" />
      },
      renderCell: member => member.email,
      compare: (a, b) => {
        return a.email.localeCompare(b.email)
      },
    }),
    createTableColumn<OrgMemberDetail>({
      columnId: 'full-name',
      renderHeaderCell: () => {
        return <T id="account.field.name" />
      },
      renderCell: member => locale === 'en'
        ? `${member.firstName} ${member.lastName}`
        : `${member.lastName} ${member.firstName}`,
    }),
    createTableColumn<OrgMemberDetail>({
      columnId: 'role',
      renderHeaderCell: () => {
        return <T id="org.role.label" />
      },
      renderCell: (member) => {
        return (
          <UpdateRoleSelect
            orgID={orgID}
            accountID={member.id}
            role={member.role}
          />
        )
      },
    }),
    createTableColumn<OrgMemberDetail>({
      columnId: 'actions',
      renderHeaderCell: () => {
        return ''
      },
      renderCell: (member) => {
        if (role === 'owner') {
          return (
            <Button
              aria-label="Remove"
              appearance="transparent"
              icon={<SubtractCircleRegular />}
              onClick={() => setRemove(member)}
            >
              <T id="org.member.table.remove" />
            </Button>
          )
        }
      },
    }),
  ]

  return (
    <DataGrid
      items={memberList}
      columns={columns}
      getRowId={member => member.id}
      focusMode="composite"
      sortable
      defaultSortState={{ sortColumn: 'email', sortDirection: 'ascending' }}
      resizableColumns
      columnSizingOptions={{
        email: { minWidth: 300 },
      }}
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>
              <Text weight="semibold">{renderHeaderCell()}</Text>
            </DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<OrgMemberDetail>>
        {({ item, rowId }) => (
          <DataGridRow<OrgMemberDetail> key={rowId}>
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  )
}
