import { z } from 'zod'
import { useDataLocal } from '@/utils/data/local'

export const LATEST_VERSION = '1.18'

const schema = z.object({
  seen: z.enum(['true', 'false']),
})

export type Version = z.infer<typeof schema>

const fallback: Version = {
  seen: 'false',
}

export function useNotifyVersion(): {
  version: Version
  updateVersion: (update: Partial<Version>) => void
} {
  const [version, updateVersion] = useDataLocal<Version>({
    queryKey: [`version/${LATEST_VERSION}`],
    localKey: `version/${LATEST_VERSION}`,
    fallback,
    schema,
  })
  return { version, updateVersion }
}
