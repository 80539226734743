import {
  Body1,
  Button,
  Subtitle2,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import {
  DocumentMultiple20Regular,
  Info20Regular,
  Pen20Regular,
} from '@fluentui/react-icons'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import type { CategoryDetail, WithTreePath } from '@aisekisan/anya-api'
import { listAnnotations, useListPages } from '@aisekisan/anya-api'
import { navigate } from 'wouter/use-browser-location'
import { getCategoryPathLabel } from './utils'
import { CategoryEdit } from './rename'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  'card': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    rowGap: tokens.spacingHorizontalL,
    backgroundColor: tokens.colorNeutralBackground1,
    alignItems: 'center',
    boxShadow: tokens.shadow4,
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.padding(tokens.spacingHorizontalL),
  },
  'column': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
    backgroundColor: tokens.colorNeutralBackground1,
  },
  'textLabel': {
    color: tokens.colorNeutralForeground3,
  },
  'title': {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
  },
  'label': {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalXS,
  },
  'row': {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
  },
  'level-1': {
    ...shorthands.margin(0),
  },
  'level-2': {
    ...shorthands.margin(0, 0, 0, '32px'),
    width: '864px',
  },
  'level-3': {
    ...shorthands.margin(0, 0, 0, '64px'),
    width: '832px',
  },
  'level-4': {
    ...shorthands.margin(0, 0, 0, '96px'),
    width: '800px',
  },
})

type Props = WithTreePath<CategoryDetail>

interface InfoFieldProps {
  icon: ReactNode
  label: ReactNode
  value: string
}

function InfoField(props: InfoFieldProps) {
  const { icon, label, value } = props
  const styles = useStyles()
  return (
    <div className={styles.label}>
      {icon}
      <Body1 className={styles.textLabel}>
        {label}
        :
        {' '}
      </Body1>
      <Body1>{value}</Body1>
    </div>
  )
}
export function CardInfo(props: Props): JSX.Element {
  const { path, value } = props

  const t = useT()
  const styles = useStyles()

  const [edit, setEdit] = useState<CategoryDetail | null>(null)
  const [annotations, setAnnotations] = useState<number>(0)

  const listPages = useListPages({ categoryID: value.id }).data

  const getLevelStyle = () => {
    const level = path.length
    switch (level) {
      case 1:
        return styles['level-1']
      case 2:
        return styles['level-2']
      case 3:
        return styles['level-3']
      case 4:
        return styles['level-4']
      default:
        return styles['level-4']
    }
  }

  useEffect(() => {
    const fetchPages = async () => {
      if (listPages) {
        try {
          const annotations = await Promise.all(
            listPages
              .map(page => page.id)
              .map(pageID => listAnnotations(pageID)),
          )
          const totalAnnotations = annotations.reduce(
            (acc, annot) => (acc += annot.length),
            0,
          )
          setAnnotations(totalAnnotations)
        }
        catch (err) {
          console.error(err)
        }
      }
    }
    fetchPages()
  }, [listPages, value.id])

  return (
    <div className={mergeClasses(styles.card, getLevelStyle())}>
      {edit && <CategoryEdit category={value} close={() => setEdit(null)} />}

      <div className={styles.column}>
        <div className={styles.title}>
          <Subtitle2 className={styles.textLabel}>
            {getCategoryPathLabel(path)}
          </Subtitle2>
          <Subtitle2>
            {t('category.label')}
            {' '}
            {value.name}
          </Subtitle2>
        </div>
        {listPages
          ? (
              <div className={styles.row}>
                <InfoField
                  icon={<DocumentMultiple20Regular />}
                  label={<T id="construction.category.pages" />}
                  value={listPages.length.toString() ?? '0'}
                />
                <InfoField
                  icon={<Pen20Regular />}
                  label={<T id="construction.category.annotations" />}
                  value={annotations.toString()}
                />
              </div>
            )
          : (
              <T id="construction.categories.empty" />
            )}
      </div>
      <div className={styles.row}>
        <Button
          icon={<Pen20Regular />}
          appearance="primary"
          onClick={() => void navigate (`/category/${value.id}`)}
        >
          <T id="construction.category.action.annotate" />
        </Button>
        <Button icon={<Info20Regular />} onClick={() => setEdit(value)}>
          <T id="construction.category.action.edit" />
        </Button>
      </div>
    </div>
  )
}
