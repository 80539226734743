import { makeStyles } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import type { FormatZoneDetail } from './utils'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  default: {
    textAlign: 'center',
    minWidth: '50px',
  },
  equip: {
    textAlign: 'center',
    minWidth: '140px',
  },
  material: {
    textAlign: 'center',
    minWidth: '150px',
  },
  insul: {
    textAlign: 'center',
    minWidth: '100px',
  },
  page: {
    textAlign: 'center',
    minWidth: '80px',
  },
  total: {
    textAlign: 'center',
    minWidth: '80px',
  },
  module: {
    textAlign: 'center',
    minWidth: '120px',
  },
})

interface Props {
  zoneHeaders: FormatZoneDetail[]
}

export function ZoneReportHeader(props: Props): ReactElement {
  const { zoneHeaders } = props
  const s = useStyles()

  return (
    <>
      <tr>
        <th rowSpan={1} className={s.equip}>
          <T id="report.header.object" />
        </th>
        <th rowSpan={1} className={s.material}>
          <T id="report.header.material" />
        </th>
        <th rowSpan={1} className={s.default}>
          <T id="report.header.type" />
        </th>
        <th rowSpan={1} className={s.default}>
          <T id="report.header.size" />
        </th>
        <th rowSpan={1} className={s.default}>
          <T id="report.header.construction_area" />
        </th>
        <th rowSpan={1} className={s.default}>
          <T id="report.header.unit" />
        </th>
        {/* Zone list */}
        {zoneHeaders.map((header) => {
          if (header.name === 'non_zone') {
            return (
              <th colSpan={1} className={s.default} key="non_zone">
                <T id="report.header.non-zone" />
              </th>
            )
          }
          return (
            <th colSpan={1} key={header.id} className={s.default}>
              {header.name}
            </th>
          )
        })}
        <th rowSpan={1} className={s.total}>
          <T id="report.header.total" />
        </th>
      </tr>

    </>
  )
}
