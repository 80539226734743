import type { EquipmentAttributeTreeInOrgPreset } from '@aisekisan/anya-api'
import { parseServerError, useEquipmentAttributeTreeOrgPresetDelete } from '@aisekisan/anya-api'
import { DeleteProperty } from '@aisekisan/preset-property'
import type { ReactElement } from 'react'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { useLocation } from '@/hooks/use-location'

interface Props {
  property: EquipmentAttributeTreeInOrgPreset
  close: () => void
}

export function DeleteOrgProperty(props: Props): ReactElement {
  const { property, close } = props
  const { id: treeID, presetID: orgPresetID } = property

  const t = useT()
  const { navigate } = useLocation()

  const deleteTree = useEquipmentAttributeTreeOrgPresetDelete({ orgPresetID })

  return (
    <>
      <DeleteProperty
        isPending={deleteTree.isPending}
        deleteTree={() => {
          deleteTree.mutate(
            { treeID },
            {
              onSuccess: () => {
                close()
                navigate(`/org-preset/${orgPresetID}`)
              },
            },
          )
        }}
        close={close}
      />
      {deleteTree.isError
        ? (
            <ErrorAlert
              title={t('preset.equipment.property.delete.error')}
              help={t('all.error.review')}
              detail={parseServerError(deleteTree.error)}
              onClose={() => deleteTree.reset()}
            />
          )
        : null}
    </>
  )
}
