import type { AnnotationDetail, Pieces, Pipes } from '@aisekisan/anya-api'
import {
  useEquipmentAttributeTrees,
  useEquipmentClasses,
} from '@aisekisan/anya-api'
import { useIntl } from '@aisekisan/bond'
import type { ReportZoneRow } from '../type'
import { groupReportZoneData } from './group'
import { transformReportData } from './transform'

interface Params {
  constructionID: string
  reportAnnots: AnnotationDetail[]
}

export type AnnotWithZone = AnnotationDetail & { zoneID: string }

export function useZoneReportRows(params: Params): ReportZoneRow[] {
  const { constructionID, reportAnnots } = params

  const { lang } = useIntl()
  const equipClasses = useEquipmentClasses().data ?? []
  const equipClassLocale = equipClasses.filter(({ locale }) => locale === lang)

  const trees = useEquipmentAttributeTrees({ constructionID }).data ?? []

  const annots = transformDataWithZone(reportAnnots)
  const rows = annots.map((annot) => {
    const equip = equipClassLocale.find(({ equipmentClass }) => {
      return equipmentClass === annot.equipmentClass
    })
    return transformReportData(annot, trees, equip ? equip.localed : '')
  })

  return groupReportZoneData(rows)
}

function transformDataWithZone(reportAnnots: AnnotationDetail[]) {
  const annots: AnnotWithZone[] = []
  reportAnnots.forEach((annot) => {
    const { dataType, data } = annot
    if (dataType === 'Pieces') {
      const rest = {
        ...annot,
        data: {
          ...(data as Pieces),
          pieces: (data as Pieces).pieces.filter(p => p.zoneID === undefined),
        },
        zoneID: '',
      }

      annots.push(rest)

      const pieceHasZone = (data as Pieces).pieces.filter(p => p.zoneID)
      pieceHasZone.forEach((p) => {
        const separated = {
          ...annot,
          data: { ...data as Pieces, pieces: [p] },
          zoneID: p.zoneID,
        } as AnnotWithZone
        annots.push(separated)
      })
    }

    if (dataType === 'Pipes') {
      const rest = {
        ...annot,
        data: {
          ...(data as Pipes),
          lineSegments: (data as Pipes).lineSegments.filter(line => line.zoneID === undefined),
        },
        zoneID: '',
      } as AnnotWithZone
      annots.push(rest)

      const segmentHasZone = (data as Pipes).lineSegments.filter(line => line.zoneID)
      segmentHasZone.forEach((line) => {
        const separated = {
          ...annot,
          data: {
            ...(data as Pipes),
            lineSegments: [line],
          },
          zoneID: line.zoneID,
        } as AnnotWithZone
        annots.push(separated)
      })
    }
  })
  return annots
}
