import type { ReactElement } from 'react'
import { useState } from 'react'
import { People24Regular } from '@fluentui/react-icons'
import type { Locale, OrgMemberDetail, OrgRole } from '@aisekisan/anya-api'
import { useOrgMemberList } from '@aisekisan/anya-api'
import { Panel, makeStyles } from '@aisekisan/bond'
import { OrgMemberRemove } from './remove'
import { OrgMemberTable } from './table'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'

const useStyles = makeStyles({
  table: {
    maxHeight: '50vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
})

interface Props {
  orgID: string
  role: OrgRole
  locale: Locale
}

export function OrgMemberList(props: Props): ReactElement {
  const { orgID, role, locale } = props

  const styles = useStyles()

  const [remove, setRemove] = useState<OrgMemberDetail | null>(null)

  const list = useOrgMemberList({ orgID }).data ?? []

  return (
    <>
      <Panel
        title={<T id="construction.member.list.title" />}
        icon={<People24Regular />}
      >
        {list && list.length > 0
          ? (
              <div className={styles.table}>
                <OrgMemberTable
                  locale={locale}
                  role={role}
                  memberList={list}
                  orgID={orgID}
                  setRemove={setRemove}
                />
              </div>
            )
          : <EmptyContent />}
      </Panel>
      {remove !== null && (
        <OrgMemberRemove
          member={remove}
          orgID={orgID}
          close={() => setRemove(null)}
        />
      )}
    </>
  )
}
