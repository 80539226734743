import type { Dispatch, ReactElement, SetStateAction } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { Panel, makeStyles, tokens } from '@aisekisan/bond'
import type { EquipmentAttributeTreeNode, SelectedNode } from '../type'
import { updateNodeInTree } from '../tree/utils'
import { SizeProperty } from '../size/size'
import { ChildrenProperty } from '../children/children'
import { InsulProperty } from '../insul/insul'
import { InsulSizeProperty } from '../insul-size/insul-size'
import { useT } from '../../libs/intl/useT'
import type { EquipmentClassDetail } from '../../type'
import { PropertyMenu } from './menu'
import type { PropertyFormBody } from './type'

const useStyles = makeStyles({
  container: {
    width: '360px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: tokens.colorNeutralBackground3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
})

interface Props {
  equipmentClasses: EquipmentClassDetail[]
  form: UseFormReturn<PropertyFormBody>
  selected: SelectedNode
  setSelected: Dispatch<SetStateAction<SelectedNode | null>>
  activeIDNodes: number[]
  insulTree: EquipmentAttributeTreeNode | null
}

export function PropertyList(props: Props): ReactElement | null {
  const {
    equipmentClasses,
    selected,
    form,
    setSelected,
    activeIDNodes,
    insulTree,
  } = props

  const treeType = form.watch('treeType')
  const root = form.watch('tree')
  const equip = form.watch('equipmentClass')

  const t = useT()
  const styles = useStyles()

  const onChange = (tree: EquipmentAttributeTreeNode) => {
    const originTree = form.getValues('tree')
    const updateTreeID = tree.id
    const newTree = updateNodeInTree(originTree, updateTreeID, tree)
    if (newTree)
      form.setValue('tree', newTree)
  }

  if (
    selected.node.children.length === 0
    && selected.node.shapeOptions === undefined
    && selected.node.insulationOptions === undefined
  )
    return null

  return (
    <Panel
      className={styles.container}
      title={[
        t('preset-property.property.when'),
        capitalizeFirstLetter(selected.node.value),
      ].join(' ')}
    >
      <div className={styles.content}>
        <div>
          <PropertyMenu
            root={root}
            node={selected.node}
            equip={Number(equip)}
            treeType={treeType}
            onChange={onChange}
          />
        </div>

        {selected.node.children.map(childrenNode => (
          <ChildrenProperty
            key={childrenNode.id}
            root={root}
            tree={childrenNode}
            activeIDNodes={activeIDNodes}
            equip={Number(equip)}
            treeType={treeType}
            selected={selected}
            setSelected={setSelected}
            onChange={onChange}
          />
        ))}
        <SizeProperty
          node={selected.node}
          onAdd={(shapeOptions, node) => {
            node.shapeOptions = { ...shapeOptions, insulShapes: {} }
            onChange(selected.node)
          }}
          onRemove={() => {
            selected.node.shapeOptions = undefined
            onChange(selected.node)
          }}
        />
        {Object.entries(selected.node.shapeOptions?.insulShapes ?? {})
          .map(([equip, insulShapes]) => {
            return (
              <InsulSizeProperty
                equipmentClasses={equipmentClasses}
                key={equip}
                equip={equip}
                insulShapes={insulShapes}
                node={selected.node}
                onChange={onChange}
              />
            )
          })}
        {Object.entries(selected.node.insulationOptions ?? {})
          .map(([material1, options]) => {
            return (
              <InsulProperty
                key={material1}
                material1={material1}
                insulOptions={options}
                insulTree={insulTree}
                node={selected.node}
                onChange={onChange}
              />
            )
          })}
      </div>
    </Panel>
  )
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
