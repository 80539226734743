import {
  Button,
  Subtitle2,
  makeStyles,
  shorthands,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import {
  FormNew20Regular,
} from '@fluentui/react-icons'
import { Fragment, useState } from 'react'
import type { EquipmentAttributeTree } from '@aisekisan/anya-api'
import { useEquipmentClasses } from '@aisekisan/anya-api'
import { PropertyButton, getEquipmentIcon } from '@aisekisan/preset-property'
import { DeleteConstructionProperty } from './delete'
import { CopyConstructionPropertyDirectly } from './copy-directly'
import { T } from '@/libs/intl/t'
import { useLocation } from '@/hooks/use-location'

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    ...shorthands.padding(tokens.spacingHorizontalL),
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    boxShadow: tokens.shadow4,
    width: '320px',
    backgroundColor: tokens.colorNeutralBackground1,
  },
  primaryButton: {
    width: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  menuButton: {
    width: '24px',
    height: '24px',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
    alignItems: 'center',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    rowGap: tokens.spacingHorizontalS,
    ...shorthands.margin(0, 0, tokens.spacingHorizontalS, 0),
  },
})

interface Props {
  constructionID: string
  properties: EquipmentAttributeTree[]
  equipmentClass: string
}

export function ConstructionEquipmentPropertyOverview(props: Props): JSX.Element {
  const { properties, constructionID, equipmentClass } = props

  const styles = useStyles()
  const { lang } = useIntl()
  const { navigate } = useLocation()

  const [deleteProperty, setDeleteProperty] = useState<string | null>(null)
  const [copyProperty, setCopyProperty] = useState<string | null>(null)

  const equipmentClassList = useEquipmentClasses().data ?? []
  const equipment = equipmentClassList.find((equipment) => {
    return equipment.equipmentClass === Number(equipmentClass)
      && equipment.locale === lang
  })

  return (
    <div className={styles.card}>
      {equipment
        ? (
            <div className={styles.label}>
              {getEquipmentIcon(equipment.localed)}
              <Subtitle2>{equipment.localed}</Subtitle2>
            </div>
          )
        : null}
      <div>
        {properties.map(property => (
          <Fragment key={property.id}>
            <PropertyButton
              id={property.id}
              type={property.treeType}
              href={`/construction-property/${property.id}`}
              setDeleteProperty={setDeleteProperty}
              setCopyProperty={setCopyProperty}
            />
            {deleteProperty === property.id
              ? <DeleteConstructionProperty property={property} close={() => setDeleteProperty(null)} />
              : null}
            {copyProperty === property.id
              ? <CopyConstructionPropertyDirectly property={property} close={() => setCopyProperty(null)} />
              : null}
          </Fragment>
        ))}
      </div>
      <div>
        <Button icon={<FormNew20Regular />} onClick={() => void navigate(`/construction-property/${constructionID}/create?equipmentClass=${equipmentClass}`)}>
          <T id="construction.property.create" />
        </Button>
      </div>
    </div>
  )
}
