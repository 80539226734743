import { BondMessageBar, Button, Text } from '@aisekisan/bond'
import { DismissRegular } from '@fluentui/react-icons'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { useT } from '../intl/useT'
import { T } from '@/libs/intl/t'

interface Props {
  title: string
  help: ReactNode
  detail: string
  onClose?: () => void
}

export function ErrorAlert(props: Props): JSX.Element {
  const { title, help, detail, onClose } = props

  const [copied, setCopied] = useState<boolean>(false)
  const t = useT()

  const copyToClipboard = async () => {
    setCopied(true)
    try {
      await navigator.clipboard.writeText(detail)
    }
    catch (err) {
      console.error(t('kit.error-alert.detail'), err)
    }
    setTimeout(() => {
      setCopied(copied => !copied)
    }, 2000)
  }

  return (
    <BondMessageBar
      title={title}
      body={(
        <div>
          <Text truncate>{help}</Text>
          <div style={{ margin: '4px 0' }}>
            <Button onClick={() => copyToClipboard()}>
              {copied
                ? (
                    <T id="kit.error-alert.copied" />
                  )
                : (
                    <T id="kit.error-alert.detail" />
                  )}
            </Button>
          </div>
        </div>
      )}
      id={new Date().toString()}
      intent="error"
      actions={(
        <Button
          onClick={() => onClose && onClose()}
          aria-label="dismiss"
          appearance="transparent"
          icon={<DismissRegular />}
        />
      )}
    />
  )
}
