import type {
  TableColumnDefinition,
} from '@aisekisan/bond'
import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Text,
  createTableColumn,
} from '@aisekisan/bond'
import { SubtractCircleRegular } from '@fluentui/react-icons'
import type {
  ConstructionMemberDetail,
  Locale,
  OrgMemberDetail,
} from '@aisekisan/anya-api'
import { UpdateRoleSelect } from './update-role'
import { T } from '@/libs/intl/t'

interface Props {
  locale: Locale
  memberList: ConstructionMemberDetail[]
  constructionID: string
  setRemove: (member: ConstructionMemberDetail) => void
}

export function ConstructionMemberTable(props: Props): JSX.Element {
  const { constructionID, setRemove, locale, memberList } = props

  const columns: TableColumnDefinition<ConstructionMemberDetail>[] = [
    createTableColumn<ConstructionMemberDetail>({
      columnId: 'email',
      renderHeaderCell: () => {
        return <T id="account.field.email" />
      },
      renderCell: member => member.email,
      compare: (a, b) => {
        return a.email.localeCompare(b.email)
      },
    }),
    createTableColumn<ConstructionMemberDetail>({
      columnId: 'full-name',
      renderHeaderCell: () => {
        return <T id="account.field.name" />
      },
      renderCell: (member) => {
        return (
          <Text>
            {locale === 'en' ? `${member.firstName} ${member.lastName}` : `${member.lastName} ${member.firstName}`}
          </Text>
        )
      },
    }),
    createTableColumn<ConstructionMemberDetail>({
      columnId: 'role',
      renderHeaderCell: () => {
        return <T id="org.role.label" />
      },
      renderCell: member => (
        <UpdateRoleSelect
          constructionID={constructionID}
          accountID={member.id}
          role={member.role}
        />
      ),
    }),
    createTableColumn<ConstructionMemberDetail>({
      columnId: 'actions',
      renderHeaderCell: () => {
        return ''
      },
      renderCell: member => (
        <Button
          aria-label="Remove"
          appearance="transparent"
          icon={<SubtractCircleRegular />}
          onClick={() => setRemove(member)}
        >
          <T id="org.member.table.remove" />
        </Button>
      ),
    }),
  ]

  return (
    <DataGrid
      items={memberList ?? []}
      columns={columns}
      sortable
      getRowId={member => member.id}
      focusMode="composite"
      resizableColumns
      columnSizingOptions={{
        'email': { minWidth: 300 },
        'full-name': { defaultWidth: 300 },
        'action': { defaultWidth: 150 },
      }}
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>
              <Text weight="semibold">{renderHeaderCell()}</Text>
            </DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<OrgMemberDetail>>
        {({ item, rowId }) => (
          <DataGridRow<OrgMemberDetail> key={rowId}>
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  )
}
