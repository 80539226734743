import { Button, Subtitle2, makeStyles, shorthands, tokens, useIntl } from '@aisekisan/bond'
import { FormNew20Regular } from '@fluentui/react-icons'
import type { EquipmentAttributeTreeInOrgPreset } from '@aisekisan/anya-api'
import { useEquipmentClasses } from '@aisekisan/anya-api'
import { PropertyButton, getEquipmentIcon } from '@aisekisan/preset-property'
import { Fragment, useState } from 'react'
import { DeleteOrgProperty } from './delete'
import { CopyOrgPropertyDirectly } from './copy-directly'
import { T } from '@/libs/intl/t'
import { useLocation } from '@/hooks/use-location'

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    boxShadow: tokens.shadow4,
    width: '320px',
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding(tokens.spacingHorizontalL),
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
    alignItems: 'center',
  },
})

interface Props {
  orgPresetID: string
  properties: EquipmentAttributeTreeInOrgPreset[]
  equipmentClass: string
}

export function OrgEquipmentPropertyOverview(props: Props): JSX.Element {
  const { properties, equipmentClass, orgPresetID } = props

  const styles = useStyles()
  const { lang } = useIntl()
  const { navigate } = useLocation()

  const [deleteProperty, setDeleteProperty] = useState<string | null>(null)
  const [copyProperty, setCopyProperty] = useState<string | null>(null)

  const equipmentClassList = useEquipmentClasses().data ?? []
  const equipment = equipmentClassList.find((equipment) => {
    return equipment.equipmentClass === Number(equipmentClass)
      && equipment.locale === lang
  })

  return (
    <div className={styles.card}>
      {equipment
        ? (
            <div className={styles.label}>
              {getEquipmentIcon(equipment.localed)}
              <Subtitle2>{equipment.localed}</Subtitle2>
            </div>
          )
        : null}
      <div>
        {properties.map(property => (
          <Fragment key={property.id}>
            <PropertyButton
              id={property.id}
              type={property.treeType}
              href={`/org-preset-property/${property.id}`}
              setDeleteProperty={setDeleteProperty}
              setCopyProperty={setCopyProperty}
            />
            {deleteProperty === property.id
              ? <DeleteOrgProperty property={property} close={() => setDeleteProperty(null)} />
              : null}
            {copyProperty === property.id
              ? <CopyOrgPropertyDirectly property={property} close={() => setCopyProperty(null)} />
              : null}
          </Fragment>
        ))}
      </div>
      <div>
        <Button
          icon={<FormNew20Regular />}
          onClick={() => void navigate(`/org-preset-property/${orgPresetID}/create?equipmentClass=${equipmentClass}`)}
        >
          <T id="construction.property.create" />
        </Button>
      </div>
    </div>
  )
}
