import type {
  AnnotationDetail,
  BoxShape,
  Circle,
  CustomShape,
  ElectricPower,
  EquipmentAttributeTree,
  EquipmentAttributeTreeNode,
  GenericAnnotationData,
  Insulation,
  Pieces,
  PipeLineSegmentExtension,
  PipeLineSegmentExtra,
  PipeLineSegmentFlat,
  PipeLineSegmentVertical,
  Pipes,
  Point,
  Rectangle,
  RefrigerantPipeShape,
  Unit,
} from '@aisekisan/anya-api'
import type { ReportZoneData } from '../type'
import type { AnnotWithZone } from './row'
import { findTree } from '@/utils/equipment-attribute-tree'

/**
 * Transform report annotation data
 * - Get material and construction area value from equipment attribute tree
 * - Convert insulation data and size data to readable format
 */
export function transformReportData(
  annot: AnnotWithZone,
  trees: EquipmentAttributeTree[],
  equipClassName: string,
): ReportZoneData {
  const {
    id,
    data,
    dataType,
    equipmentType: equipType,
    equipmentClass,
    zoneID,
  } = annot

  const materialTree = findTree(trees, 'material', equipmentClass)
  const material = mappingValueByIDs(
    materialTree?.tree,
    data.material,
  )

  const cAreaTree = findTree(trees, 'construction_area', equipmentClass)
  const cArea = mappingValueByIDs(
    cAreaTree?.tree,
    data.constructionArea,
  ).join(' / ')

  return {
    id,
    value: getValue(annot).toFixed(2),
    dataType,
    equipType,
    equipClassName,
    size: getDisplayReportSize(annot),
    shape: annot.data.shape?.kind ?? '',
    unit: getDisplayReportUnit(annot),
    material1: material.at(0) ?? '',
    material2: material.at(1) ?? '',
    cArea,
    zoneID: zoneID || 'non_zone',
  }
}

function getDisplayReportSize(annot: AnnotationDetail): string {
  const { shape } = annot.data
  if (!shape)
    return ''

  switch (shape.kind) {
    case 'Circle':{
      const { diameter } = shape.data as Circle
      return `${diameter}`
    }
    case 'Rectangle': {
      const { width, length } = shape.data as Rectangle
      return `${width} x ${length}`
    }
    case 'CustomShape': {
      const { size } = shape.data as CustomShape
      return `${size}`
    }
    case 'RefrigerantPipeShape': {
      const {
        liquidPipeDiameter,
        suctionPipeDiameter,
      } = shape.data as RefrigerantPipeShape
      return `${liquidPipeDiameter} - ${suctionPipeDiameter}`
    }
    case 'ElectricPower': {
      const { kilowatt } = shape.data as ElectricPower
      return `${kilowatt}`
    }
    case 'BoxShape': {
      const { width, height, length } = shape.data as BoxShape
      return `${width} x ${height} x ${length}`
    }
    default:
      throw new Error(`Invalid shape ${shape.kind}`)
  }
}

function getDisplayReportUnit(annot: AnnotationDetail): Unit {
  switch (annot.dataType) {
    case 'Pieces':
      return 'piece'
    case 'Pipes':
      return 'm'
    case 'GenericAnnotationData':
      return (annot.data as GenericAnnotationData).unit
    case 'Insulation':
      return 'mm'
  }
}

function calculateDistanceInMM(point1: Point, point2: Point, scale: number) {
  const deltaX = point2.x - point1.x
  const deltaY = point2.y - point1.y
  const distanceInPx = Math.sqrt(deltaX ** 2 + deltaY ** 2)
  return distanceInPx * scale
}

function getValue(annotation: AnnotationDetail): number {
  const { dataType, data, pageScale } = annotation
  switch (dataType) {
    case 'Pieces':
      return (data as Pieces).pieces.length
    case 'Pipes':
      return (data as Pipes).lineSegments.reduce((acc, { type, inner }) => {
        switch (type) {
          case 'Flat': {
            const [point1, point2] = (inner as PipeLineSegmentFlat).points
            return acc += calculateDistanceInMM(point1, point2, pageScale)
          }
          case 'Vertical':
            return acc += (inner as PipeLineSegmentVertical).length
          case 'Extension':
            return acc += (inner as PipeLineSegmentExtension).length
          case 'Extra':
            return acc += (inner as PipeLineSegmentExtra).length
          default:
            return acc
        }
      }, 0)
    case 'Insulation':
      return (data as Insulation).length
    case 'GenericAnnotationData':
      return (data as GenericAnnotationData).unitValue
  }
}

function mappingValueByIDs(
  tree: EquipmentAttributeTreeNode | undefined,
  ids: number[] | undefined,
): string[] {
  const result: string[] = []

  const searchTree = (node: EquipmentAttributeTreeNode | undefined) => {
    if (!node || !ids)
      return

    if (ids.includes(node.id))
      result.push(node.value)

    if (node.children) {
      node.children.forEach((child) => {
        searchTree(child)
      })
    }
  }

  searchTree(tree)

  return result
}
