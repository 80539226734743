import {
  Body1,
  Button,
  Caption1,
  Card,
  CardFooter,
  CardHeader,
  Subtitle2,
  makeStyles,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import {
  ArrowRight20Regular,
  ChevronRight20Regular,
  Edit20Regular,
  NumberSymbol16Regular,
  People16Regular,
} from '@fluentui/react-icons'
import type { ReactNode } from 'react'
import { useState } from 'react'
import type { ConstructionDetail } from '@aisekisan/anya-api'
import { useCategoryList, useConstructionMemberList } from '@aisekisan/anya-api'
import { navigate } from 'wouter/use-browser-location'
import { getCategoryLabel } from '@/components/category/utils'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ConstructionEdit } from '@/components/construction/edit'

const useStyles = makeStyles({
  card: {
    ...shorthands.padding(tokens.spacingHorizontalL),
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: tokens.colorNeutralForeground3,
    justifyContent: 'flex-start',
    columnGap: tokens.spacingHorizontalXXS,
  },
  item: {
    display: 'grid',
  },
  items: {
    width: '200px',
    rowGap: tokens.spacingHorizontalL,
    display: 'flex',
    flexDirection: 'column',
  },
  categories: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    width: 'calc(100% - 200px)',
    alignItems: 'baseline',
    rowGap: tokens.spacingVerticalS,
  },
  hintText: {
    color: tokens.colorNeutralForeground3,
  },
  construction: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...shorthands.padding(0),
  },
  category: {
    ...shorthands.padding(0),
  },
})

function Item(props: {
  label: ReactNode
  value: ReactNode
  icon: ReactNode
}) {
  const { label, value, icon } = props
  const styles = useStyles()

  return (
    <div className={styles.item}>
      <Caption1 className={styles.labelIcon}>
        {icon}
        {' '}
        {label}
      </Caption1>
      <Body1 style={{ color: tokens.colorNeutralForeground1 }}>{value}</Body1>
    </div>
  )
}

interface Props { construction: ConstructionDetail }

function ConstructionDetailCard(props: Props) {
  const { construction } = props
  const { id, code } = construction

  const t = useT()
  const styles = useStyles()

  const categories = useCategoryList({ constructionID: id }).data ?? []

  const members = useConstructionMemberList({ constructionID: id }).data ?? []
  const memberRoleEditor = (members ?? []).filter(
    member => member.role === 'editor',
  ).length

  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <Item
          label={<T id="construction.field.code" />}
          icon={<NumberSymbol16Regular />}
          value={code}
        />

        <Item
          label={<T id="construction.member.list.title" />}
          icon={<People16Regular />}
          value={(
            <div>
              {`${members?.length}`}
              {' '}
              {memberRoleEditor !== 0 ? `(${memberRoleEditor} ${t('construction.member.editors')})` : null}
            </div>
          )}
        />
      </div>
      <div className={styles.categories}>
        {categories.length === 0
          ? (
              <div>
                <Body1 style={{ color: tokens.colorNeutralForeground3 }}>
                  <T id="construction.categories.empty" />
                </Body1>
              </div>
            )
          : (
              <>
                {categories.slice(0, 2).map(category => (
                  <Button
                    appearance="transparent"
                    onClick={() => void navigate (`/category/${category.value.id}`)}
                    className={styles.construction}
                    icon={<ChevronRight20Regular />}
                    key={category.value.id}
                  >
                    {`${t('construction.field.category')} ${getCategoryLabel(category)}`}
                  </Button>
                ))}

                {categories.length > 2 && (
                  <Button
                    appearance="transparent"
                    onClick={() => void navigate(`/construction/${construction.id}`)}
                    className={styles.category}
                  >
                    <Body1 className={styles.hintText}>
                      {`+ ${categories.length - 2} ${t('category.categories.label')}`}
                    </Body1>
                  </Button>
                )}
              </>
            )}
      </div>
    </div>
  )
}

export function ConstructionCard(props: Props) {
  const { construction } = props

  const styles = useStyles()

  const [edit, setEdit] = useState<boolean>(false)

  return (
    <Card className={styles.card}>
      <CardHeader
        header={(
          <Subtitle2>
            <T id="construction.title {{.name}}" params={{ name: construction.name }} />
          </Subtitle2>
        )}
      />
      <ConstructionDetailCard construction={construction} />
      <CardFooter>
        <Button
          appearance="primary"
          icon={<ArrowRight20Regular />}
          style={{ fontWeight: 'normal' }}
          onClick={() => void navigate (`/construction/${construction.id}`)}
        >
          <T id="construction.explore.title" />
        </Button>
        <Button icon={<Edit20Regular />} style={{ fontWeight: 'normal' }} onClick={() => setEdit(true)}>
          <T id="construction.edit.title" />
        </Button>
      </CardFooter>
      {edit && <ConstructionEdit construction={construction} close={() => setEdit(false)} />}
    </Card>
  )
}
