import type { ReactElement } from 'react'
import { useState } from 'react'
import { Body1, Button, OverlaySpinner, Panel, makeStyles, tokens } from '@aisekisan/bond'
import { People24Regular, PersonAddRegular } from '@fluentui/react-icons'
import type { OrgInvitationDetail, OrgRole } from '@aisekisan/anya-api'
import { useOrgInvitationList } from '@aisekisan/anya-api'
import { InviteMemberCreate } from './invite'
import { InvitationRevoke } from './revoke-invitation'
import { OrgInvitationTable } from './table'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  table: {
    maxHeight: '50vh',
    overflowY: 'auto',
    marginBottom: tokens.spacingVerticalL,
  },
  help: {
    color: tokens.colorNeutralForeground3,
  },
})

interface Props {
  orgID: string
  role: OrgRole
}

export function OrgInvitationList(props: Props): ReactElement {
  const { orgID, role } = props

  const styles = useStyles()

  const [add, setAdd] = useState<boolean>(false)
  const [revoke, setRevoke] = useState<OrgInvitationDetail | null>(null)

  const { data: list, isPending } = useOrgInvitationList({ orgID, status: 'pending', role })

  return (
    <div style={{ position: 'relative' }}>
      <Panel
        title={<T id="org.invitation.list.title" />}
        icon={<People24Regular />}
        actions={(
          <Button icon={<PersonAddRegular />} onClick={() => setAdd(true)}>
            <T id="org.invitation.list.invite" />
          </Button>
        )}
      >
        <OverlaySpinner visible={isPending} appearance="primary" />
        {list && list.length > 0
          ? (
              <div className={styles.table}>
                <OrgInvitationTable
                  role={role}
                  invitations={list}
                  setRevokeInvitation={setRevoke}
                />
              </div>
            )
          : null}
        <Body1 className={styles.help}>
          <T id="org.invitation.invite.guideline" />
          <ul style={{ margin: 0 }}>
            <li>
              <T id="org.invitation.invite.guideline.1" />
            </li>
            <li>
              <T id="org.invitation.invite.guideline.2" />
            </li>
          </ul>
        </Body1>
      </Panel>
      {add && (
        <InviteMemberCreate
          orgID={orgID}
          role={role}
          close={() => setAdd(false)}
        />
      )}
      {revoke && (
        <InvitationRevoke
          invitation={revoke}
          orgID={orgID}
          close={() => setRevoke(null)}
        />
      )}
    </div>
  )
}
