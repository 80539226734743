import { OverlaySpinner } from '@aisekisan/bond'
import { useAccountInfo } from '@aisekisan/anya-api'
import { AppPage } from '@/AppPage'
import { OrgOverviewInfo } from '@/components/org/overview/list'
import { T } from '@/libs/intl/t'

export function OrgListPage(): JSX.Element {
  const account = useAccountInfo()

  if (account.isLoading)
    return <OverlaySpinner visible={account.isLoading} />

  return (
    <AppPage title={account.data ? <T id="Greetings {{.name}}" params={{ name: account.data.lastName }} /> : <T id="Greetings" />}>
      <OrgOverviewInfo />
    </AppPage>
  )
}
