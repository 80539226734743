import type {
  CategoryDetail,
  ConstructionDetail,
  OrgReportingTemplateDetail,
  PageDetail,
} from '@aisekisan/anya-api'
import { useReportAnnotations } from '@aisekisan/anya-api'
import { Card, Panel, REPORT_TEMPLATES, makeStyles, tokens } from '@aisekisan/bond'
import { type ReactElement, useState } from 'react'
import { Info24Regular } from '@fluentui/react-icons'
import { useReportHeads } from './head.ts'
import { useReportRows } from './row/row.ts'
import { FireReport } from './fire/summary.tsx'
import { DefaultReport } from './default/summary.tsx'
import { ReportTemplate } from './template.tsx'
import { DefaultReportExport } from './default/export.tsx'
import { FireReportExport } from './fire/export.tsx'
import { ZoneReportExport } from './zone/export.tsx'
import { ZoneReport } from './zone/summary.tsx'
import { useZoneReportRows } from './zone/row/row.ts'
import { T } from '@/libs/intl/t.tsx'
import { useT } from '@/libs/intl/useT.ts'

interface Props {
  construction: ConstructionDetail
  category: CategoryDetail
  pages: PageDetail[]
  templates: OrgReportingTemplateDetail[]
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: tokens.spacingHorizontalL,
    flexDirection: 'column',
  },
})
/**
 * When Report Summary and Exported Report are different, determine the
 * appropriate template by using templateCategory.
 *
 * When portal support custom templates, determine the appropriate template by
 * using templateEngine.
 *
 * For now, Report Summary and Exported Report are expected to be the same
 * and portal does not support custom templates so we only use templateID to
 * determine the template.
 */
export function ReportSummary(props: Props): ReactElement {
  const { construction, category, pages, templates } = props
  const constructionID = construction.id
  const categoryID = category.id

  const t = useT()
  const [templateID, setTemplateID] = useState(
    templates.at(0)?.template as REPORT_TEMPLATES ?? REPORT_TEMPLATES.default,
  )

  const { data: reportAnnots = [] } = useReportAnnotations({
    constructionID,
    categoryID,
  })

  const reportHeads = useReportHeads({ constructionID, pages, reportAnnots })
  const reportRows = useReportRows({ constructionID, reportAnnots })

  const zoneReportRows = useZoneReportRows({ constructionID, reportAnnots })

  const s = useStyles()
  if (reportAnnots.length === 0) {
    return (
      <Panel
        title={<T id="report.summary" />}
        icon={<Info24Regular />}
      >
        <Card>
          <T id="table.no.data" />
        </Card>
      </Panel>
    )
  }

  const getExport = () => {
    switch (templateID) {
      case REPORT_TEMPLATES.default:
        return (
          <DefaultReportExport
            construction={construction}
            category={category}
            reportHeads={reportHeads}
            reportRows={reportRows}
          />
        )
      case REPORT_TEMPLATES.fire:
        return (
          <FireReportExport
            construction={construction}
            category={category}
            reportHeads={reportHeads}
            reportRows={reportRows}
          />
        )
      case REPORT_TEMPLATES.zone:
        return (
          <ZoneReportExport
            construction={construction}
            category={category}
            reportHeads={reportHeads}
            reportRows={zoneReportRows}
          />
        )
      default:
        throw new Error(`Unknown template ${templateID}`)
    }
  }

  const getTemplate = () => {
    switch (templateID) {
      case REPORT_TEMPLATES.default:
        return (
          <DefaultReport
            construction={construction}
            category={category}
            reportHeads={reportHeads}
            reportRows={reportRows}
          />
        )
      case REPORT_TEMPLATES.fire:
        return (
          <FireReport
            construction={construction}
            category={category}
            reportHeads={reportHeads}
            reportRows={reportRows}
          />
        )
      case REPORT_TEMPLATES.zone:
        return (
          <ZoneReport
            construction={construction}
            category={category}
            reportHeads={reportHeads}
            reportRows={zoneReportRows}
          />
        )
      default:
        throw new Error(`Unknown template ${templateID}`)
    }
  }

  return (
    <Panel
      title={<T id="report.summary" />}
      icon={<Info24Regular />}
      actions={(
        <div>
          {getExport()}
        </div>
      )}
    >
      <div className={s.container}>
        <ReportTemplate
          templates={templates.map(template => ({
            ...template,
            templateName: template.template === '' ? t('category.report.template-default') : template.templateName,
          }))}
          templateID={templateID}
          setTemplateID={setTemplateID}
        />
        {getTemplate()}
      </div>
    </Panel>
  )
}
