import { Body1, Button, Caption1, Subtitle2, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { Edit20Regular, Eye20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { GlobalPresetDetail, OrgPresetDetail } from '../type'
import { T } from '../libs/intl/t'
import { useT } from '../libs/intl/useT'
import { DisplayMarket } from './overview/market'
import { DisplayLocale } from './overview/locale'

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: tokens.colorNeutralBackground1,
    rowGap: tokens.spacingVerticalL,
    boxShadow: tokens.shadow4,
    maxWidth: '320px',
    width: '320px',
    boxSizing: 'border-box',
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.padding(tokens.spacingHorizontalL),
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
  },
  label: {
    color: tokens.colorNeutralForeground3,
  },
  description: {
    height: '80px',
    WebkitLineClamp: 4,
    ...shorthands.overflow('hidden !important'),
  },
})

interface Props {
  preset: OrgPresetDetail | GlobalPresetDetail
  href?: string
  setEditPreset?: (editPreset: boolean) => void
  target?: '_blank' | '_self'
}

export function PresetCard(props: Props): ReactElement {
  const { preset, href, setEditPreset, target = '_self' } = props

  const t = useT()
  const styles = useStyles()

  return (
    <div className={styles.card}>
      <Subtitle2>{`${t('preset-property.preset.label')} ${preset.name}`}</Subtitle2>

      <div className={styles.flexRow}>
        <div className={styles.item}>
          <Caption1 className={styles.label}>
            <T id="preset-property.preset.market" />
          </Caption1>
          <DisplayMarket market={preset.market} />
        </div>
        <div className={styles.item}>
          <Caption1 className={styles.label}>
            <T id="preset-property.preset.locale" />
          </Caption1>
          <DisplayLocale locale={preset.locale} />
        </div>
      </div>

      <Body1 className={styles.description}>{preset.description}</Body1>

      <div className={styles.actions}>
        {href && (
          <Button icon={<Eye20Regular />} as="a" href={href} target={target}>
            <T id="preset-property.preset.view" />
          </Button>
        )}
        {setEditPreset && (
          <Button icon={<Edit20Regular />} onClick={() => setEditPreset(true)}>
            <T id="preset-property.action.edit" />
          </Button>
        )}
      </div>
    </div>
  )
}
