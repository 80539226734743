import { Body1, Link, useIntl } from '@aisekisan/bond'
import { useState } from 'react'
import { ResetPasswordAcceptPage } from './accept-reset-password'
import { AccountLayout } from '@/components/account/layout'
import { RequestResetPasswordForm } from '@/components/account/reset-password/request-form'
import { ResetPasswordSentEmail } from '@/components/account/reset-password/reset-sent'
import { useStyles } from '@/components/account/styles'
import { useLocation } from '@/hooks/use-location'
import { T } from '@/libs/intl/t'

export function ResetPasswordPage(): JSX.Element {
  const [sent, setSent] = useState<false | string>(false)

  const { queryParams } = useLocation()
  const token = queryParams.token
  const styles = useStyles()
  const { lang } = useIntl()

  if (token)
    return <ResetPasswordAcceptPage key={token} token={token} />

  const NavFooterSignIn = (
    <div className={styles.navigateFlex}>
      <div>
        <Body1 className={styles.textBody}>
          {lang === 'en' ? <T id="reset-password.remember-password" /> : null}
          {' '}
          <Link href="/sign-in" inline>
            <T id="sign-in.title" />
          </Link>
        </Body1>
      </div>
    </div>
  )

  const NavFooterNeedAssistance = (
    <div className={styles.navigateFlex}>
      <div>
        <Body1 className={styles.textBody}>
          <T id="reset-password.need-assistance" />
          {' '}
          <Link href="" inline>
            <T id="reset-password.contact-support" />
          </Link>
        </Body1>
      </div>
    </div>
  )
  return (
    <AccountLayout navigate={sent ? NavFooterNeedAssistance : NavFooterSignIn}>
      {sent === false
        ? (
            <div className={styles.card}>
              <RequestResetPasswordForm setSent={setSent} />
            </div>
          )
        : (
            <ResetPasswordSentEmail email={sent} setSent={setSent} />
          )}
    </AccountLayout>
  )
}
