import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

const KEY = ['construction-zone']

export function useConstructionZoneList(params: { constructionID: string }) {
  const { constructionID } = params

  return useQuery({
    queryKey: [KEY, 'list', constructionID],
    queryFn: () => {
      return server.listZones(constructionID, 0, 200)
    },
  })
}

export function useUpdateConstructionZone(params: { constructionID: string, zoneID: string }) {
  const { constructionID, zoneID } = params

  const client = useQueryClient()

  return useMutation({
    mutationFn: (payload: {
      name: string
      color: string
    }) => {
      return server.updateZone(zoneID, constructionID, payload)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: [KEY, 'list', constructionID],
      })
    },
  })
}

export function useCreateConstructionZone(params: { constructionID: string }) {
  const { constructionID } = params

  const client = useQueryClient()

  return useMutation({
    mutationFn: (payload: {
      name: string
      color: string
    }) => {
      return server.createZone(constructionID, payload)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: [KEY, 'list', constructionID],
      })
    },
  })
}
