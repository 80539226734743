import type { ReportZoneRow } from './type'
import type { FormatZoneDetail } from './utils'
import { useT } from '@/libs/intl/useT'

interface Props {
  zoneHeaders: FormatZoneDetail[]
  reportRows: ReportZoneRow[]
}

export function ZoneReportBody(props: Props) {
  const { zoneHeaders, reportRows } = props
  const t = useT()

  return (
    <>
      {reportRows.map((row, index) => {
        return (
          <tr key={`${row.id}-${index}`}>
            {/* Object */}
            <td>{row.equipClassName}</td>
            {/* Material */}
            <td align="center">
              {row.material1}
              {row.material2 && ` / ${row.material2}`}
            </td>
            {/* Type */}
            <td align="center">
              {row.equipType === 'NONFD'
                ? t('report.type.nonfd')
                : row.equipType === 'FD'
                  ? t('report.type.fd')
                  : row.equipType}
            </td>
            {/* Size */}
            <td align="center">{getDisplayReportSize(row)}</td>
            {/* CA */}
            <td align="center">{row.cArea}</td>
            {/* Unit */}
            <td align="center">
              {row.unit === 'piece'
                ? t('report.unit.piece')
                : row.unit}
            </td>
            {zoneHeaders.map((col) => {
              if (col.id === row.zoneID) {
                return (
                  <td align="center" key={col.id}>
                    {row[row.zoneID]}
                  </td>
                )
              }
              return (
                <td align="center" key={col.id}>
                  { }
                </td>
              )
            })}
            {/* Total */}
            <td align="center">
              {zoneHeaders.reduce((acc, col) => {
                const total = row[col.id]
                return total ? acc += Number(total) : acc
              }, 0).toFixed(1)}
            </td>
          </tr>
        )
      })}
    </>
  )
}

function getDisplayReportSize(row: ReportZoneRow): string {
  switch (row.shape) {
    case 'Circle':
    case 'Rectangle':
    case 'CustomShape':
    case 'RefrigerantPipeShape':
    case 'BoxShape':
      return row.size ? `${row.size} mm` : ''
    case 'ElectricPower':
    default:
      return `${row.size}`
  }
}
